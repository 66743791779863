<template>
    <div v-if="confirmShowBox" class="confirm-your-email-box">
      <div class="close-icon" @click="confirmHideBox">
        <i class='bx bx-x'></i>
      </div>
      <div class="text-1">
        <p><b>Confirm your email</b> to access our link building services and boost visibility.<br> If you haven't received the email, request a re-send.</p>
      </div>
      <div class="btn-box">
        <router-link to="/" class="btn-1" @click="resendEmail">Resend Confirmation Email</router-link>
      </div>
   
    </div>
  </template>
  
  <script>
  import { useToast } from "vue-toastification";
  const toast = useToast();

  export default {
    components: {},
    mounted() {
      this.checkUrlParams();
      if (this.userData.emailVerified) {
        this.confirmShowBox = false;
      }else{
        this.confirmShowBox = true;
      }
    },
    data() {
      return {
        confirmShowBox: false,
        resendEmailData: {
          email: "",
          utm_source: "direct",
          utm_medium: "direct",
          utm_campaign: "direct",
          da: "",
          category: "",
        },
        data: {
          verification_code: ''
        }
      };
    },
    methods: {
      getQueryParameterValue(parameterName) {
        var queryString = location.search.substring(1);
        var queryParams = queryString.split("&");

        for (var i = 0; i < queryParams.length; i++) {
          var param = queryParams[i].split("=");
          if (param[0] === parameterName) {
            return decodeURIComponent(param[1]);
          }
        }
        return null;
      },
      checkUrlParams() {
        var daValue = this.getQueryParameterValue("da");
        var categoryValue = this.getQueryParameterValue("category");
        var sourceValue = this.getQueryParameterValue("utm_source");
        var mediumValue = this.getQueryParameterValue("utm_medium");
        var campaignValue = this.getQueryParameterValue("utm_campaign");

        if (daValue !== null && categoryValue !== null) {
          this.resendEmailData.da = daValue;
          this.resendEmailData.category = categoryValue;
          this.queryParamValue = `?da=${daValue}&category=${categoryValue}`;
          if(sourceValue){
            this.resendEmailData.utm_source = sourceValue;
            this.queryParamValue += `&utm_source=${sourceValue}`;
          }
          if(mediumValue){
            this.resendEmailData.utm_medium = mediumValue;
            this.queryParamValue += `&utm_medium=${mediumValue}`;
          }
          if(campaignValue){
            this.resendEmailData.utm_campaign = campaignValue;
            this.queryParamValue += `&utm_campaign=${campaignValue}`;
          }
          this.queryParam = true;
        }

        if(this.$route.query.verification_code){
          this.data.verification_code = this.$route.query.verification_code;
          this.verification();
        }
      },
      confirmHideBox() {
        this.confirmShowBox = false;
      },
      async verification() {
        console.log(this.$store.getters["Auth/getUser"]);
        this.$parent.loading = true;
        try {
          let res = await this.$store.dispatch(
            "Auth/isEmailVerifiedAction",
            {
              'verification_code' : this.data.verification_code
            },
            {
              root: true,
            }
          );
          if(res.success){
            this.confirmShowBox = false;
          }
          this.$parent.loading = false;
          this.$router.replace({ path: '/dashboard' });
          toast.success(res.message, { timeout: 3000 });
          console.log(this.$store.getters["Auth/getUser"]);
        } catch (error) {
            this.$parent.loading = false;
            toast.error(error.response.data.errors, { timeout: 3000 });
            this.errors = error.response.data.errors;
        }
      },
      async resendEmail() {
        this.resendEmailData.email = this.userData.email;
        if(this.resendEmailData.email){
          try {
            this.loading = true;
            let res = await this.$store.dispatch(
              "Auth/resendEmailForVerificationAction", this.resendEmailData, {
                root: true,
              }
            );

            if (res.success) {
              this.loading = false;
              toast.success(res.message, { timeout: 3000 });
            }
          } catch (error) {
            this.loading = false;
            toast.error(error.response.data.errors, { timeout: 3000 });
            this.errors = error.response.data.errors;
          }
        }
      },
    },
    computed: {
      userData() {
        const data = this.$store.getters["Auth/getUser"];
        return {
          email: data.email,
          emailVerified: data.is_email_verified,
        };
      },
    },
    watch: {
      userData(newVal) {
        if (newVal.emailVerified) {
          this.confirmShowBox = false;
        }else{
          this.confirmShowBox = true;
        }
      }
    }
  };
  </script>
  