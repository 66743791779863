<template>
  <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
    <div class="text_heading_top_bar">
            <div class="text-heading-2">Affiliate Commissions</div>
          </div>
    <div class="table-responsive">
      <div :class="{'scrollable': earninglists.length > 10}">
        <table class="addon-table">
          <tr>
            <th>Date</th>
            <th>Agency</th>
            <th>Order Value</th>
            <th>Bonus</th>
            <th>Status</th>
          </tr>
          <tr v-for="earning_list in earninglists" :key="earning_list.agency_id">
            <td>{{earning_list.created_at}}</td>
            <td>{{earning_list.agency_id}}</td>
            <td>{{ numberFormat(earning_list.order_total, 'currency') }}</td>
            <td>{{ numberFormat(earning_list.earned_bonus, 'currency') }}</td>
            <td><span :style="getStatusStyle(earning_list.status)" >{{earning_list.status}}</span></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { numberFormat } from '@/prod.js';

export default {
  props: ["earning_lists"],
  watch: {
    earning_lists() {
      this.earninglists = this.earning_lists;
    },
  },
  components: {},
  mounted() {},
  data() {
    return {
      numberFormat,
      loading: false,
      earninglists:[],

      getStatusStyle(status) {
      switch (status) {
        case 'unpaid':
          return { backgroundColor: '#FFF3E5', color: '#FFAA44' };
        case 'paid':
          return { backgroundColor: '#ECF8EF', color: '#43CC72' };
        case 'pending':
          return { backgroundColor: '#FFE5E5', color: '#FF4444' };
        case 'requested':
        return { backgroundColor: '#f6d3ff', color: '#cb00ff' };
        default:
          return { backgroundColor: '#FFFFFF', color: '#000000' };
      }
    }
    };
  },
  methods: {
    redirectURL(url) {
      const pattern = /^(http:\/\/|https:\/\/)/i;
      const hasHttpOrHttps = pattern.test(url);

      let finalUrl = url;
      if (!hasHttpOrHttps) {
        finalUrl = "https://" + url;
      }

      const newTab = window.open(finalUrl, "_blank");
      if (newTab) {
        newTab.focus();
      }
    },
  },
  computed: {
    liveLinksLength() {
      return this.earning_lists ? true : false;
    },
  },
};
</script>
