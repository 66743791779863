import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import LoginView from '../views/auth/Login.vue'
import ForgetView from '../views/auth/Forget.vue'
import SignupView from '../views/auth/Signup.vue'
import ResetView from '../views/auth/Reset.vue'
import DraftOrderView from '../views/DraftOrderPage.vue'
// import EmailVerificationView from '../views/auth/EmailVerification.vue'
import PaymentView from '../views/PaymentPage.vue'
import ProductView from '../views/ProductPage.vue'
import OrderView from '../views/OrderPage.vue'
import OrderDetailView from '../views/OrderDetailPage.vue'
import UserProfileView from '../views/UserProfilePage.vue'
import BillingView from '../views/BillingPage.vue'
import BillingInvoiceView from '../views/BillingInvoicePage.vue'
import UsersView from '../views/UsersPage.vue'
import AddUserView from '../views/AddUserPage.vue'
import UserInvitationView from '../views/UserInvitationPage.vue'
import ByDomainView from '../views/reports/DomainPage.vue'
import ByDomainDetailView from '../views/reports/DomainDetailPage.vue'
import ByOrderView from '../views/reports/OrderPage.vue'
import ByOrderDetailView from '../views/reports/Order_DetailPage .vue'
import ByDestinationView from '../views/reports/DestinationPage.vue'
import ByDestinationListView from '../views/reports/DestinationListPage.vue'
import MaintenanceView from '../views/503Page.vue'
import NotFoundView from '../views/notFoundPage.vue'
import MissedOpportunityView from '../views/MissedOpportunityPage.vue'
import MissedOpportunityOrderView from '../views/MissedOpportunityOrderPage.vue'
import PackagesView from '../views/packages/PackagesPage.vue'
import DigitalPrView from '../views/DigitalPrPage.vue'
import EnterpriseView from '../views/EnterprisePage.vue'
import AllAffiliateStatsView from '../views/AllAffiliateStatsView.vue'
import CreateAffiliateUrl from '../views/CreateAffiliateUrl.vue'


import store from '@/store';

const routes = [
  {
    path: "/dashboard",
    redirect: "dashboard",
    meta: {isAuthRequired: true},
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: { title: 'Dashboard' },
        component: DashboardView
      },
      {
        path: '/draft-order',
        name: 'draft-order',
        meta: { title: 'Draft Orders' },
        component: DraftOrderView
      },
      {
        path: '/order-summary',
        name: 'order-summary',
        meta: { title: 'Order Summary' },
        component: PaymentView
      },
      {
        path: '/product/:id?',
        name: 'product',
        meta: { title: 'Products' },
        component: ProductView
      },
      {
        path: '/order/:id?',
        name: 'order1',
        meta: { title: 'Orders' },
        component: OrderView
      },
      {
        path: '/order-detail/:id',
        name: 'order-detail',
        meta: { title: 'Order Detail' },
        component: OrderDetailView
      },
      {
        path: '/user-profile',
        name: 'user-profile',
        meta: { title: 'Profile' },
        component: UserProfileView
      },
      {
        path: '/billing',
        name: 'billing',
        meta: { title: 'Billings' },
        component: BillingView
      },
      {
        path: '/invoice/:id',
        name: 'billing-Invoice',
        meta: { title: 'Invoice' },
        component: BillingInvoiceView
      },
      {
        path: '/users',
        name: 'users',
        meta: { title: 'User Management' },
        component: UsersView
      },
      {
        path: '/add-user/:id?',
        name: 'add-user',
        meta: { title: 'Add New User' },
        component: AddUserView
      },

      {
        path: '/domain',
        name: 'domain',
        meta: { title: 'Agency Reports - By Domain' },
        component: ByDomainView
      },

      {
        path: '/domain-detail/:domain',
        name: 'domain-detail',
        meta: { title: 'Domain Name' },
        component: ByDomainDetailView
      },

      {
        path: '/byorder',
        name: 'order',
        meta: { title: 'Agency Reports - By Order' },
        component: ByOrderView
      },

      {
        path: '/report-order-detail/:id',
        name: 'report-order-detail',
        meta: { title: 'Order ID' },
        component: ByOrderDetailView
      },
      
      {
        path: '/destination',
        name: 'destination',
        meta: { title: 'Agency Reports - By Destination' },
        component: ByDestinationView
      },

      {
        path: '/destination-list/:destination',
        name: 'destination-list',
        meta: { title: 'Destination List' },
        component: ByDestinationListView
      },
      
      {
        path: '/missed-opportunity',
        name: 'missed-opportunity',
        meta: { title: 'Premium Placements' },
        component: MissedOpportunityView
      },

      {
        path: '/missed-opportunity-order/:id?',
        name: 'missed-opportunity-order',
        meta: { title: 'Premium Placements' },
        component: MissedOpportunityOrderView
      },
      
      {
        path: '/packages',
        name: 'packages',
        meta: { title: 'Packages' },
        component: PackagesView
      },

      {
        path: '/digitalpr',
        name: 'digitalpr',
        meta: { title: 'Digitalpr' },
        component: DigitalPrView
      },

      {
        path: '/enterprise',
        name: 'enterprise',
        meta: { title: 'Enterprise' },
        component: EnterpriseView
      },

      {
        path: '/affiliate',
        name: 'affiliate',
        meta: { title: 'Affiliate' },
        component: AllAffiliateStatsView
      },

      {
        path: '/affiliate-url',
        name: 'affiliate-url',
        meta: { title: 'Affiliate URL' },
        component: CreateAffiliateUrl
      },
      
    ],
  },
  // {
  //   path: '/email-verification',
  //   name: 'email-verification',
  //   component: EmailVerificationView,
  //   meta: {isAuthRequired: false},
  // },
  {
    path: '/503',
    name: '503',
    meta: {isAuthRequired: false},
    component: MaintenanceView
  },
  { 
    path: '/:pathMatch(.*)*', 
    component: NotFoundView, 
    meta: {isAuthRequired: true, title:'Page Not Found'}
  },
  {
    path: "/",
    redirect: "login",
    meta: {isAuthRequired: false},
    children: [
      {
        path: '/login',
        name: 'login',
        meta: { title: 'Log into LBHQ', description: 'Login to LinkBuilding HQ today to take advantage of white hat link building services. Boost your clients’ or your backlink profile with LinkBuilding HQ, today!' },
        component: LoginView
      },
      {
        path: '/register',
        name: 'signup',
        meta: { title: 'Register | LBHQ', description: 'Register to Link Building HQ today to gain premium backlinks for your clients or yourself. Signup for free, NO CREDIT CARD NEEDED. Quick and Easy registration.' },
        component: SignupView
      },
      {
        path: '/forget-password',
        name: 'forget',
        meta: { title: '' },
        component: ForgetView
      },
      {
        path: '/reset',
        name: 'reset',
        meta: { title: '' },
        component: ResetView
      },
      {
        path: '/accept-invitation',
        name: 'accept-invitation',
        meta: { title: '' },
        component: UserInvitationView
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Restore saved scroll position (e.g., on back/forward navigation).
    } else {
      return { top: 0 }; // Scroll to top for new navigations.
    }
  },
});

router.beforeEach((to,from,next)=>{
    if(to.meta.isAuthRequired && !store.state.Auth.user.token){
      if(to.name == 'product'){
        next({name: 'signup', query: {...to.query}});
      }
      else if(to.name == 'dashboard'){
        next({name: 'login', query: {...to.query}});
      }else{
        next({name: 'login'});
      }
    }else if(store.state.Auth.user.token && !to.meta.isAuthRequired){
      next({name:'dashboard'});
    }else{
      const existingMetaDescription = document.querySelector('meta[name="description"]');
      // Check if the meta tag exists before removing it
      if (existingMetaDescription) {
          // Remove the existing meta tag from the document head
          existingMetaDescription.remove();
      }
      if(to.path == '/login' || to.path == '/register'){
        document.title = `${to.meta.title}`;
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.setAttribute('content', to.meta.description);
        document.head.appendChild(metaDescription);
      }
      else if (to.meta.title !== undefined && to.meta.title !== null && to.meta.title !== '') {
        document.title = `Link Building HQ - ${to.meta.title}`;
      } else {
        document.title = `Link Building HQ`;
      }
      next();
    }
});

export default router
