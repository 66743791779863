<template>
    <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
        <div class="text_heading_top_bar">
            <div class="text-heading-2">Affiliate URL's</div>
        </div>
        <div class="table-responsive">
            <div :class="{'scrollable': urllists.length > 10}">
            <table class="addon-table">
                <tr>
                <th>Affiliate</th>
                <th>URL's</th>
                <th>Commission %</th>
                <th>Discount %</th>
                <th>Actions</th>
                </tr>
                <tr v-for="urllist in urllists" :key="urllist.agency_id">
                <td>{{ urllist.affiliate }}</td>
                <td class="destination-text">
                    <a @click="copyText(urllist.referral_url)" >
                        <span>{{ urllist.referral_url }}</span>
                        <i  v-if="urllist.referral_url" class='bx bx-copy'
                        ></i>
                    
                    </a>
                </td>
                <td>{{ parseFloat(urllist.commision).toFixed(2) }}%</td>
                <td>{{ parseFloat(urllist.discount).toFixed(2) }}%</td>
                <td colspan="2" class="action-btns">
                    <div class="btn-group">
                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                        ...
                    </button>

                    <ul class="dropdown-menu" role="menu">
                        <li><a href="javascript:;" v-b-modal.modal-box-3 @click="currentAffiliateUrl(urllist.id)">Edit</a></li>
                        <!-- <li><a href="javascript:;" @click="deleteButtonPopupDraft(urllist.id)">Remove Order</a></li> -->
                    </ul>

                    </div>
                </td>
                </tr>
            </table>
            </div>
        </div>
        <b-modal
            id="modal-box-3"
            class="affiliate-modal-box"
            centered
            title="Update Affiliate URL"
            :no-close-on-backdrop="true"
            >
            <form>
                <b-row>
                <b-col md="12">
                    <div class="form-group">
                        <label>Update Commission Rate</label>
                        <b-form-input
                            id="input-2"
                            v-model="commission"
                            @keyup="setAffiliateUrl"
                            @keypress="validateInput"
                            placeholder="5"
                        ></b-form-input>
                        <span v-if="errors.commission" class="required-text invalid-feedback" style="display: block" role="alert">
                            <strong>{{
                                errors.commission[0]
                            }}</strong>
                            </span>
                    </div>
                </b-col>
                <b-col md="12">
                    <div class="form-group">
                        <label>Update Discount Rate</label>
                        <b-form-input
                            id="input-2"
                            v-model="discount"
                            @keyup="setAffiliateUrl"
                            placeholder="5"
                        ></b-form-input>
                        <span v-if="errors.discount" class="required-text invalid-feedback" style="display: block" role="alert">
                            <strong>{{
                                errors.discount[0]
                            }}</strong>
                            </span>
                    </div>
                </b-col>
                <b-col md="12" v-if="!AffiliateUrlHide">
                    <div class="form-group">
                        <label>Referral URL</label>
                        <b-form-input
                            id="input-2"
                            v-model="referralLink"
                            readonly
                        ></b-form-input>
                    </div>
                </b-col>
                <b-col md="12">
                    <div class="btn-box-list">
                    <a href="javascript:;" @click="updateAffiliateUrl" class="btn-1"
                        >Update URL
                    </a>
                    </div>
                </b-col>
                </b-row>
            </form>
            </b-modal>
    </div>
  </template>
  
  <script>
  import { numberFormat } from '@/prod.js';
  import { useToast } from "vue-toastification";
  const toast = useToast();
  import url from "@/prod.js";

  export default {
    props: ["url_lists"],
    watch: {
        url_lists() {
            this.urllists = this.url_lists;
        },
        userData(newVal) {
            if (newVal.emailVerified) {
                this.userData.emailVerified = true;
            }else{
                this.userData.emailVerified = false;
            }
        },
    },
    components: {},
    mounted() {
        if(this.userData.role != 'Agency' || !this.userData.emailVerified){
            this.$router.push("/dashboard");
        }
    },
    data() {
      return {
        numberFormat,
        loading: false,
        urllists:[],
        appURL: url.appUrl,
        referralLink: "",
        commission: '',
        discount: '',
        errors: [],
        encodedUrl: '',
        AffiliateUrlHide: true,
        updateId: '',
      };
    },
    methods: {
        currentAffiliateUrl(id){
            const result = this.urllists.find(item => item.id === id);
            this.updateId = id;
            this.commission = parseFloat(result['commision']).toFixed(2);
            this.discount = parseFloat(result['discount']).toFixed(2);
            this.referralLink = result['referral_url'];
        },
        setAffiliateUrl(){
            if (this.commission) {
                if (this.commission % 1 !== 0) {  // Check if commission is a float
                    this.discount = (10 - this.commission).toFixed(2);  // Apply toFixed(2) for float values
                } else {
                    this.discount = (10 - this.commission);  // Use integer value for whole numbers
                }
                this.encodedUrl = '';
                this.encodedUrl = this.userData.referral_id + '&c=' + this.commission + '&d=' + this.discount;
                this.encodedUrl = window.btoa(this.encodedUrl);
                this.referralLink = this.appURL + '/register?ref=' + this.encodedUrl;
            }else{
                this.discount = '';
            }
        },
        async updateAffiliateUrl() {
            this.errors = [];
            this.loading = true;
            try {
                let res = await this.$store.dispatch("AgencyUsers/updateAffiliateUrlDataAction", {
                    id: this.updateId,
                    commission: this.commission,
                    discount: this.discount,
                    referral_url: this.encodedUrl
                });
                if(res.success){
                    toast.success(res.message, { timeout: 3000 });
                    window.location.reload();
                }else{
                    toast.error(res.error, { timeout: 3000 });
                }
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.errors = error.response.data.errors;
            }
        },
        copyText(value) {
            const tempInput = document.createElement("input");
            tempInput.value = value;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);
            toast.success("URL copied", { timeout: 1500 });
        },
        validateInput(event) {
        const char = event.key; // Get the key pressed
        const input = event.target.value; // Current input value

        // Allow digits, one decimal point, and navigation keys
        if (
            !/^\d$/.test(char) && // Allow digits
            char !== '.' &&       // Allow decimal point
            char !== 'Backspace' && // Allow Backspace
            char !== 'ArrowLeft' && // Allow Left Arrow
            char !== 'ArrowRight' && // Allow Right Arrow
            char !== 'Delete'        // Allow Delete
        ) {
            event.preventDefault();
        }

        // Prevent multiple decimal points
        if (char === '.' && input.includes('.')) {
            event.preventDefault();
        }

        // Prevent starting with a decimal point
        if (char === '.' && input === '') {
            event.preventDefault();
        }

        // Prevent more than 2 digits after the decimal point
        // const decimalPart = input.split('.')[1];
        // if (decimalPart && decimalPart.length >= 2 && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(char)) {
        //     event.preventDefault();
        // }
        },    
    },
    computed: {
        userData() {
            const data = this.$store.getters["Auth/getUser"];
            return {
                name: data.name,
                email: data.email,
                balance: data.balance,
                role: data.role,
                profileImage: data.image,
                referral_id: data.referral_id,
                emailVerified: data.is_email_verified,
            };
        },
    },
  };
  </script>
  