<template>
    <div class="home">
      <LoaderComponent v-show="loading" />
      <HeaderView />
      <section
        class="dashboard-page-main-sec order-page-main-sec track-order-main-sec agency-design-grid-right draft-order-page-sec user-management affiliate-page-main-sec"
      >
      
        <div class="dashboard-text-box">
          <h2 class="text-heading">
            Create Affiliate URL's
          </h2>
        </div>

        <div class="draft-order-sec affiliate-URL-form">
            <div class="order-list-tabs">
              <div class="order-input-field">
                <div class="date-picker-box">
                  <div class="form-row">
                    <b-col md="12">
                      <p class="note-text">
                        <b>Note:</b> Team up and save: Create a referral link and split 10% with a friend or colleague.
                      </p>
                    </b-col>
                    <div class="col-md-2">
                      <label>Set Commission Rate</label>
                      <b-form-input
                        id="input-2"
                        v-model="commission"
                        @keyup="setAffiliateUrl"
                        @keypress="validateInput"
                        placeholder="5"
                      ></b-form-input>
                      <span v-if="errors.commission" class="required-text invalid-feedback" style="display: block" role="alert">
                        <strong>{{
                            errors.commission[0]
                        }}</strong>
                        </span>
                    </div>
                    <div class="col-md-2">
                      <label>Set Discount Rate</label>
                      <b-form-input
                        id="input-2"
                        v-model="discount"
                        @keyup="setAffiliateUrl"
                        placeholder="5"
                        readonly
                      ></b-form-input>
                      <span v-if="errors.discount" class="required-text invalid-feedback" style="display: block" role="alert">
                        <strong>{{
                            errors.discount[0]
                        }}</strong>
                        </span>
                    </div>
                    <div class="col-md-3" v-if="!AffiliateUrlHide">
                      <label>Referral URL</label>
                      <b-form-input
                        id="input-2"
                        v-model="referralLink"
                        readonly
                      ></b-form-input>
                    </div>
                    <b-col md="3">
                      <div class="btn-box-list">
                        <a
                          href="javascript:;"
                          class="btn-1"
                          @click="createAffiliateUrl()"
                          >Create URL
                        </a>
                      </div>
                    </b-col>
                  </div>
                </div>
              </div>
            </div>
        </div>

  
        <div class="row">
          <div class="col-md-12">
            <AffiliateUrlsList :url_lists="url_lists"/>
          </div>
        </div>
      </section>
    </div>
  </template>
   
<script>
  import HeaderView from "@/components/layout/HeaderComponent.vue";
  import AffiliateUrlsList from "@/components/affiliateComponents/AffiliateUrlList.vue";
  import LoaderComponent from "@/components/LoaderComponent.vue";
  import url from "@/prod.js";
  import { useToast } from "vue-toastification";
  const toast = useToast();
  
export default {
    name: "DashboardView",
    components: {
      HeaderView,
      AffiliateUrlsList,
      LoaderComponent,
    },
    mounted() {
      if(this.userData.role != 'Agency' || !this.userData.emailVerified){
        this.$router.push("/dashboard");
      }
      this.getAffiliateUrlList();
    //   this.referralLink = this.userData.referral_id;
    },
  
    data() {
      return {
        loading: true,
        appURL: url.appUrl,
        url_lists: [],
        referralLink: "",
        commission: '',
        discount: '',
        errors: [],
        encodedUrl: '',
        AffiliateUrlHide: true,
      };
    },
    methods: {
      async getAffiliateUrlList(){
          try{
          let res = await this.$store.dispatch("AgencyUsers/getAffiliateUrlListDataAction");
          this.url_lists = res.data;
          this.loading = false;
          }catch(error){
          this.errors = error.response.data.errors;
          this.loading = false;
          }
      },
      setAffiliateUrl(){
        if (this.commission) {
          if (this.commission % 1 !== 0) {  // Check if commission is a float
              this.discount = (10 - this.commission).toFixed(2);  // Apply toFixed(2) for float values
          } else {
              this.discount = (10 - this.commission);  // Use integer value for whole numbers
          }
          this.encodedUrl = '';
          this.encodedUrl = this.userData.referral_id + '&c=' + this.commission + '&d=' + this.discount;
          this.encodedUrl = window.btoa(this.encodedUrl);
          this.referralLink = this.appURL + '/register?ref=' + this.encodedUrl;
        }else{
          this.discount = '';
        }
      },
      async createAffiliateUrl() {
          this.errors = [];
          this.loading = true;
          try {
              let res = await this.$store.dispatch("AgencyUsers/createAffiliateUrlDataAction", {
                  commission: this.commission,
                  discount: this.discount,
                  referral_url: this.encodedUrl
              });
              if(res.success){
                  toast.success(res.message, { timeout: 3000 });
              }else{
                  toast.error(res.error, { timeout: 3000 });
              }
              this.getAffiliateUrlList();
              this.commission = '';
              this.discount = '';
              this.referralLink = '';
              this.loading = false;
          } catch (error) {
              this.loading = false;
              this.errors = error.response.data.errors;
          }
      },
      validateInput(event) {
        const char = event.key; // Get the key pressed
        const input = event.target.value; // Current input value

        // Allow digits, one decimal point, and navigation keys
        if (
            !/^\d$/.test(char) && // Allow digits
            char !== '.' &&       // Allow decimal point
            char !== 'Backspace' && // Allow Backspace
            char !== 'ArrowLeft' && // Allow Left Arrow
            char !== 'ArrowRight' && // Allow Right Arrow
            char !== 'Delete'        // Allow Delete
        ) {
            event.preventDefault();
        }

        // Prevent multiple decimal points
        if (char === '.' && input.includes('.')) {
            event.preventDefault();
        }

        // Prevent starting with a decimal point
        if (char === '.' && input === '') {
            event.preventDefault();
        }

        // Prevent more than 2 digits after the decimal point
        const decimalPart = input.split('.')[1];
        if (decimalPart && decimalPart.length >= 2 && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(char)) {
            event.preventDefault();
        }
      },
    },
    watch: {
        userData(newVal) {
            if (newVal.emailVerified) {
                this.userData.emailVerified = true;
            }else{
                this.userData.emailVerified = false;
            }
        },
    },
    computed: {
        userData() {
            const data = this.$store.getters["Auth/getUser"];
            return {
                name: data.name,
                email: data.email,
                balance: data.balance,
                role: data.role,
                profileImage: data.image,
                referral_id: data.referral_id,
                emailVerified: data.is_email_verified,
            };
        },
    },
};
</script>
  