import apiInstance from "./apiInstance";

export class ProductServices{

    static getProductAPI(){
        return apiInstance.get('/api/get-products');
    }

    static getAddonProductAPI(){
        return apiInstance.get('/api/add-on-products');
    }

    static addDraftOrderAPI(payload){
        return apiInstance.post('/api/add-draft-order',payload);
    }

    static getCountriesAPI(){
        return apiInstance.get('/api/get-countries');
    }

    static getStatesApi(id){
        return apiInstance.get(`/api/get-states/${id}`);
    }

    static getCitiesApi(id){
        return apiInstance.get(`/api/get-cities/${id}`);
    }

    static addOrderApi(payload){
        return apiInstance.post('/api/add-order',payload);
    }

    static addBalanceApi(payload){
        return apiInstance.post('/api/add-balance',payload);
    }

    static getOrdersApi(payload){
        return apiInstance.get(`/api/orders-list/${payload.per_page}?page=${payload.page}`,{ params: payload });
    }

    static getTabsDetailApi(){
        return apiInstance.get('/api/tabs-detail');
    }

    static applyCouponApi(payload){
        return apiInstance.post('/api/apply-coupon',payload);
    }

    static getExistingCardsAPI(){
        return apiInstance.get('/api/existing-card');
    }
    static getDraftOrderAPI(payload){
        return apiInstance.get(`/api/draft-order-list/${payload.per_page}?page=${payload.page}`,{ params: payload });
    }
    static deleteDraftOrderAPI(payload){
        return apiInstance.post('/api/delete-draft', payload);
    }

    static approvedDraftOrderAPI(payload){
        return apiInstance.post('/api/draft-orders-assign-approved-at', payload);
    }
    static domainDataAPI(payload){
        return apiInstance.post('/api/domain-data', payload);
    }
    static orderPlanCountDataAPI(){
        return apiInstance.get('/api/get-order-plan-count');
    }
    static selectedCardDetailApi(id){
        return apiInstance.get(`/api/get-card-detail/${id}`);
    }

    static getReferralCouponApi(){
        return apiInstance.get('/api/get-referral-coupon');
    }
    
    static stripePaymentMethodApi(payload){
        return apiInstance.post('/api/stripe-payment-method', payload);
    }

    static stripeSavePaymentMethodApi(payload){
        return apiInstance.post('/api/stripe-saved-payment-method', payload);
    }
}