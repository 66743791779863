<template>
  <div class="order-count-list">
    <ul>
      <li style="background-color: #F7FFF9; border: 1px solid #1F9950">
        <div class="text-2">
          <StatsLoaderComponent v-show="$parent.loarder1"/>
          <div
            class="count-text" v-if="!$parent.loarder1"
            v-text="Math.floor(stats.total_users_signup).toLocaleString()"
          >
          </div>
          Total referrals</div>
          <div class="text-1">
          <div class="icon-box">
            <img src="@/assets/common/images/referrals.svg" />
          </div>                                                                                                      
     
        </div>
      </li>
      <li style="background-color: #FEF6F9; border: 1px solid #CC527F">

        <div class="text-2">
          <StatsLoaderComponent v-show="$parent.loarder2"/>
          <div
            class="count-text" v-if="!$parent.loarder2"
            v-text="numberFormat(stats.total_earning, 'currency')"
          ></div>
          Total earning</div>
          <div class="text-1">
          <div class="icon-box">
            <img src="@/assets/common/images/earnings.svg" />
          </div>
        </div>
      </li>
      <li style="background-color: #FFFCF7; border: 1px solid #CC9E52">
       
        <div class="text-2">
          <StatsLoaderComponent v-show="$parent.loarder3"/>
          <div
            class="count-text" v-if="!$parent.loarder3"
            v-text="numberFormat(stats.paid_bonus, 'currency')"
          ></div>
          Total withdrawal </div>
          <div class="text-1">
          <div class="icon-box">
            <img src="@/assets/common/images/withdrawal.svg" />
          </div>
        </div>
      </li>
      <li style="background-color: #F7F9FF; border: 1px solid #5270CC">
        
        <div class="text-2">
          <StatsLoaderComponent v-show="$parent.loarder4"/>
          <div
            class="count-text" v-if="!$parent.loarder4"
            v-text="numberFormat(stats.unpaid_bonus, 'currency')"
          ></div>
          Current balance</div>
          <div class="text-1">
          <div class="icon-box">
            <img src="@/assets/common/images/Wallet.svg" />
          </div>
        
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import "@vuepic/vue-datepicker/dist/main.css";
import StatsLoaderComponent from "@/components/StatsLoaderComponent.vue";
import { numberFormat } from '@/prod.js';

export default {
  components: {
    StatsLoaderComponent,
  },
  props: ['affiliateStatsData'],
  watch: {
    affiliateStatsData() {
        this.stats.paid_bonus = this.affiliateStatsData.paid_bonus;
        this.$parent.loarder1 = false;
        this.stats.total_earning = this.affiliateStatsData.total_earning;
        this.$parent.loarder2 = false;
        this.stats.total_users_signup = this.affiliateStatsData.total_users_signup;
        this.$parent.loarder3 = false;
        this.stats.unpaid_bonus = this.affiliateStatsData.unpaid_bonus;
        this.$parent.loarder4 = false;
        // this.stats.total_revenue = numberFormat(this.affiliateStatsData.total_revenue,'currency');
        // this.$parent.loarder5 = false;
        // this.stats.pending_links = this.affiliateStatsData.pending_links;
        // this.$parent.loarder6 = false;
        // this.stats.avg_link_cost = numberFormat(this.affiliateStatsData.avg_link_cost,'currency');
        // this.$parent.loarder7 = false;
        // this.stats.avg_da_delivered = numberFormat(this.affiliateStatsData.avg_da_delivered,'number');
        // this.$parent.loarder8 = false;
    },
  },
  mounted() {},
  data() {
    return {
      numberFormat,
      stats: {
        paid_bonus: 0,
        total_earning: 0,
        total_users_signup: 0,
        unpaid_bonus: 0,
        // pending_links: 0,
        // avg_link_cost: 0,
        // avg_da_delivered: 0,
      }
    };
  },
  methods: {},
};
</script>
