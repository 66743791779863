<template>
    <div class="premium-enable-box" v-if="this.userData.emailVerified">
        <div class="premium-enable-btn" v-if="premiumPlacementRequestButton">
        <p>Premium Opportunities</p>
        <a class="btn-3" href="javascript:;" v-b-modal.modal-box-3> Enable Access </a>
        </div>
        <div class="premium-message-show" v-if="premiumPlacementRequestMessage">
        {{ premiumPlacementRequestMessage }}
        </div>
    </div>

    <b-modal
      id="modal-box-3"
      class="rivision-modal-box"
      centered
      title="Request Premium placement"
      :no-close-on-backdrop="true"
    >
        <form>
            <b-row>
            <b-col md="12">
                <div class="form-group">
                <label>Type</label>
                <b-form-select
                    id="input-3"
                    v-model="premiumPlacement.type"
                    @change="premiumPlacementType($event)"
                >
                    <option value="null">Please select an option</option>
                    <option value="local">Local</option>
                    <option value="niche">Niche</option>
                    <option value="large_publications">Large Publications</option>
                </b-form-select>
                <span
                    v-if="errors && errors.type"
                    class="required-text invalid-feedback"
                    style="display: block"
                    role="alert"
                >
                    <strong>{{ errors.type[0] }}</strong>
                </span>
                </div>
            </b-col>
            <b-col md="12" v-if="regionShow">
                <div class="form-group">
                <label>Enter Region</label>
                <b-form-input
                    id="input-6"
                    v-model="premiumPlacement.detail"
                    type="text"
                    rows="2"
                    required
                ></b-form-input>
                <span
                    v-if="errors.detail"
                    class="required-text invalid-feedback"
                    style="display: block"
                    role="alert"
                >
                    <strong>{{ errors.detail[0] }}</strong>
                </span>
                </div>
            </b-col>
            <b-col md="12" v-if="nicheShow">
                <div class="form-group">
                <label>By Category</label>
                <b-form-select
                    id="input-3"
                    v-model="premiumPlacement.category"
                    :options="primaryCategoryList"
                    required
                ></b-form-select>
                <span
                    v-if="errors.category"
                    class="required-text invalid-feedback"
                    style="display: block"
                    role="alert"
                >
                    <strong>{{ errors.category[0] }}</strong>
                </span>
                </div>
            </b-col>

            <b-col md="12">
                <div class="form-group">
                <label>Additional Comments</label>
                <b-form-textarea
                    id="input-6"
                    v-model="premiumPlacement.comments"
                    type="text"
                    rows="3"
                    required
                ></b-form-textarea>
                <span
                    v-if="errors.comments"
                    class="required-text invalid-feedback"
                    style="display: block"
                    role="alert"
                >
                    <strong>{{ errors.comments[0] }}</strong>
                </span>
                </div>
            </b-col>
            <b-col md="12">
                <div class="btn-box-list">
                <a href="javascript:;" @click="premiumPlacementRequest" class="btn-1"
                    >Request
                </a>
                </div>
            </b-col>
            </b-row>
        </form>
    </b-modal>
</template>

<script>
import jquery from "jquery";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
    name: "PremiumPlacementEnableAccessComponent",
    mounted() {
        this.checkPremiumPlacementRequestOption();
        this.categoryList();
    },
    data() {
        return {
            premiumPlacement: {
                type: null,
                region: "",
                detail: "",
                category: "",
                comments: "",
            },
            premiumPlacementRequestButton: false,
            premiumPlacementRequestMessage: "",
            primaryCategoryList: [{ text: "Select Category", value: null }],
            regionShow: false,
            nicheShow: false,
            errors: [],
            message: "Thank you, we will email you once the list has been populated"
        }
    },
    methods: {
        async checkPremiumPlacementRequestOption() {
            try {
                let res = await this.$store.dispatch(
                "Dashboard/checkPremiumPlacementRequestOptionAction"
                );
                if (res) {
                if (res.is_allow == 0) {
                    this.premiumPlacementRequestButton = false;
                    this.premiumPlacementRequestMessage = this.message;
                } else if (res.is_allow == 1) {
                    this.premiumPlacementRequestButton = true;
                    this.premiumPlacementRequestMessage = "";
                }
                }
            } catch (e) {
                this.loading = false;
            }
        },
        async categoryList() {
            try {
                let res = await this.$store.dispatch(
                "MissedOpportunity/getAllCategoriesDataAction"
                );
                res.forEach((item) => {
                this.primaryCategoryList.push({ text: item.name, value: item.name });
                });
            } catch (e) {
                this.loading = false;
            }
        },
        premiumPlacementType(type) {
            this.errors = [];
            this.premiumPlacement.type = type;
            this.premiumPlacement.detail = "";
            this.premiumPlacement.category = null;
            if (type == "local") {
                this.nicheShow = false;
                this.regionShow = true;
            } else if (type == "niche") {
                this.regionShow = false;
                this.nicheShow = true;
            } else {
                this.regionShow = false;
                this.nicheShow = false;
            }
        },
        async premiumPlacementRequest() {
            this.errors = [];
            if (this.premiumPlacementRequestButton) {
                this.loading = true;
                if (this.premiumPlacement.type == null || this.premiumPlacement.type == "null") {
                this.errors["type"] = ["Please select a type"];
                this.loading = false;
                } else {
                try {
                    let res = await this.$store.dispatch(
                    "Dashboard/premiumPlacementRequestAction",
                    this.premiumPlacement,
                    {
                        root: true,
                    }
                    );
                    this.loading = false;
                    jquery(".btn-close").trigger("click");
                    toast.success(res.message, { timeout: 3000 });
                    this.premiumPlacementRequestButton = false;
                    this.premiumPlacementRequestMessage = this.message;
                    // setTimeout(() => {
                    //   window.location = window.location.origin + "/dashboard";
                    // }, 500);
                } catch (error) {
                    this.loading = false;
                    this.errors = error.response.data.errors;
                }
                }
            }
        },
    },
    computed: {
        userData() {
            const data = this.$store.getters["Auth/getUser"];
            return {
                name: data.name,
                email: data.email,
                balance: data.balance,
                role: data.role,
                profileImage: data.image,
                emailVerified: data.is_email_verified,
            };
        },
    },
}
</script>