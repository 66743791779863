import { AgencyUserServices } from '@/services/agencyUserServices';
export default {
    namespaced: true,

    actions:{

        async addUsersDataAction(_, payload) {
            let res = await AgencyUserServices.addUsersDataAPI(payload);
            let data = res.data;
            return data;
        },

        async getUserListDataAction (_, payload){
            let res = await AgencyUserServices.getUserListDataAPI(payload);
            let data = res.data;
            return data;
        },

        async getEditUserDataAction (_, payload){
            let res = await AgencyUserServices.getEditUserDataAPI(payload);
            let data = res.data;
            return data;
        },

        async updateUserDataAction (_, payload){
            let res = await AgencyUserServices.updateUserDataAPI(payload);
            let data = res.data;
            return data;
        },
        async deleteUserDataAction (_, payload){
            let res = await AgencyUserServices.deleteUserDataAPI(payload);
            let data = res.data;
            return data;
        },

        

        async getAffiliateStatsDataAction (){
            let res = await AgencyUserServices.getAffiliateStatsDataAPI();
            let data = res.data;
            return data;
        },


        async getAffiliateListDataAction (){
            let res = await AgencyUserServices.getAffiliateListDataAPI();
            let data = res.data;
            return data;
        },

        async getAffiliateHistoryDataAction (){
            let res = await AgencyUserServices.getAffiliateHistoryDataAPI();
            let data = res.data;
            return data;
        },
        
        async getAffiliateUrlListDataAction (){
            let res = await AgencyUserServices.getAffiliateUrlListDataAPI();
            let data = res.data;
            return data;
        },

        async createAffiliateUrlDataAction(_, payload) {
            let res = await AgencyUserServices.createAffiliateUrlDataAPI(payload);
            let data = res.data;
            return data;
        },

        async updateAffiliateUrlDataAction(_, payload) {
            let res = await AgencyUserServices.updateAffiliateUrlDataAPI(payload);
            let data = res.data;
            return data;
        },

        async requestPayoutDataAction (){
            let res = await AgencyUserServices.requestPayoutDataAPI();
            let data = res.data;
            return data;
        },
        
    }
}