import apiInstance from "./apiInstance";

export class AgencyUserServices{

    static addUsersDataAPI(payload){
        return apiInstance.post('/api/add-agency-user', payload);
    }
    
    static getUserListDataAPI(payload){
        return apiInstance.get(`/api/users-list/${payload.per_page}?page=${payload.page}`,{ params: payload });
    }

    static getEditUserDataAPI(id){
        return apiInstance.get(`/api/edit-user/${id}`);
    }

    static updateUserDataAPI(payload){
        return apiInstance.post(`/api/update-user/${payload.id}`, payload);
    }

    static deleteUserDataAPI(payload){
        return apiInstance.post('/api/delete-associate', payload);
    }

    static getAffiliateStatsDataAPI(){
        return apiInstance.get('/api/affiliate-stats');
    }

    static getAffiliateListDataAPI(){
        return apiInstance.get('/api/affiliate-earning-history');
    }

    static getAffiliateHistoryDataAPI(){
        return apiInstance.get('/api/affiliate-payout-history');
    }

    static getAffiliateUrlListDataAPI(){
        return apiInstance.get('/api/get-affiliate-urls');
    }

    static createAffiliateUrlDataAPI(payload){
        return apiInstance.post('/api/create-affiliate-url', payload);
    }
    
    static updateAffiliateUrlDataAPI(payload){
        return apiInstance.post('/api/update-affiliate-url', payload);
    }

    static requestPayoutDataAPI(){
        return apiInstance.post('/api/request-payout');
    }
}