<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section
      class="dashboard-page-main-sec order-page-main-sec track-order-main-sec affiliate-page-main-sec agency-design-grid-right"
    >
      <div class="dashboard-text-box">
        <h2 class="text-heading">
          All Affiliates
        </h2>
        
        <div class="btn-box select-domain-box">
          <router-link to="/affiliate-url" class="btn-1">Create Affiliate Url</router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="text_heading_top_bar">
            <div class="text-heading-2" style="font-size: 18px; font-weight: 500; padding-bottom: 30px;">Overview</div>
          </div>
          <UserStatesView :affiliateStatsData="affiliateStatsData" />
        </div>
      </div>


      <div class="row">
        <div class="col-md-6">
          <EarningHistoryView :earning_lists="earning_lists"/>
        </div>
        <div class="col-md-6">
        
          <PayoutHistoryView :earning_lists="earning_lists" :historyPayouts="historyPayouts" :showPayoutBtn="showPayoutBtn" @requestPayoutOption="requestPayoutOption"/>
        </div>
      </div>
    </section>
  </div>
</template>
 
<script>
import HeaderView from "@/components/layout/HeaderComponent.vue";
import UserStatesView from "@/components/affiliateComponents/UserStates.vue";
import EarningHistoryView from "@/components/affiliateComponents/EarningHistory.vue";
import PayoutHistoryView from "@/components/affiliateComponents/PayoutHistory.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import swal from "sweetalert2";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "DashboardView",
  components: {
    HeaderView,
    UserStatesView,
    EarningHistoryView,
    PayoutHistoryView,
    LoaderComponent,
  },
  mounted() {
    if(this.userData.role != 'Agency' || !this.userData.emailVerified){
      this.$router.push("/dashboard");
    }
    setTimeout(() => (this.loading = false), 3000);
    this.affiliateStats();
    this.getaffliateCommisionList();
    this.affiliatePayoutHistory();
    this.referralLink = this.userData.referralLink;
  },

  data() {
    return {
      dashboardDraft: true,
      loading: true,
      filter: {
        s: "",
        e: "",
        order_date: "",
        columnTo: "id",
        orderBy: "desc",
        per_page: 5,
        page: 1,
        last_page: 1,
        length: "",
        date_form: "",
        date_to: "",
      },
      rows: [],
      prows: [],
      page: 1,
      per_page: 5,
      expanded: null,
      affiliateStatsData: [],
      draft_orders: [],
      earning_lists: [],

      historyPayouts:[],

      referralLink: "",
      // DRStatusChartData: [],
      clientStatusChartData: [],
      all_clients: [],
      search: {
        client: "all",
      },
      loarder1: false,
      loarder2: false,
      loarder3: false,
      loarder4: false,
      premiumPlacementRequestButton: false,
      premiumPlacementRequestMessage: '',
      packages: [],
      errors: [],
      showPayoutBtn: false,
    };
  },
  methods: {
    async affiliateStats(){
      this.loading = true;
      this.loarder1 = true;
      this.loarder2 = true;
      this.loarder3 = true;
      this.loarder4 = true;
      try{
        let res = await this.$store.dispatch("AgencyUsers/getAffiliateStatsDataAction");
        this.affiliateStatsData = res;
      }catch(error){
        this.errors = error.response.data.errors;
      }
      },
    async getaffliateCommisionList(){
      try{
        let res = await this.$store.dispatch("AgencyUsers/getAffiliateListDataAction");
        this.earning_lists = res.data;
        this.showPayoutBtn = res.show_payout_btn;
      }catch(error){
        this.errors = error.response.data.errors;
      }
    },

    async affiliatePayoutHistory(){
      try{
        let res = await this.$store.dispatch("AgencyUsers/getAffiliateHistoryDataAction");
        this.historyPayouts = res.data;
      }catch(error){
        this.errors = error.response.data.errors;
      }
    },

    async requestPayoutOption() {
      this.loading = true;
      try {
        let res = await this.$store.dispatch("Profile/getPayoutDetailsDataAction");
        if (res.success) {
          this.loading = false;
          swal
          .fire({
            icon: "warning",
            title: "Are you sure?",
            width: 600,
            showCancelButton: true,
            confirmButtonColor: "#00b6cb",
            cancelButtonColor: "#ff0e52",
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.requestPayout();
            }
          });
        }else{
          this.loading = false;
          swal.fire({
            icon: "warning",
            text: "Please fill payout details first from your profile section!",
          });
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async requestPayout() {
      this.loading = true;
      try {
        let res = await this.$store.dispatch("AgencyUsers/requestPayoutDataAction");
        if (res.success) {
          this.getaffliateCommisionList();
          this.loading = false;
          toast.success(res.message, { timeout: 3000 });
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    copyText() {
      const element = this.$refs.message;
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand("copy");
      toast.success("URL copied", { timeout: 1500 });
    },
  },
  watch: {
    userData(newVal) {
        if (newVal.emailVerified) {
            this.userData.emailVerified = true;
        }else{
            this.userData.emailVerified = false;
        }
    },
  },
  computed: {
    userData() {
      const data = this.$store.getters["Auth/getUser"];
      return {
        name: data.name,
        email: data.email,
        balance: data.balance,
        role: data.role,
        profileImage: data.image,
        referralLink: data.referral_link,
        emailVerified: data.is_email_verified,
      };
    },
  },
};
</script>
