import { ProductServices } from '@/services/productServices';
export default {
    namespaced: true,

    actions:{

        async getProductDataAction (){
            let res = await ProductServices.getProductAPI();
            let data = res.data;
            return data;
        }, 

        async getAddonProductDataAction (){
            let res = await ProductServices.getAddonProductAPI();
            let data = res.data;
            return data;
        }, 

        async saveDraftOrderAction(_, payload) {
            let res = await ProductServices.addDraftOrderAPI(payload);
            let data = res.data;
            return data;
        },

        async getCountriesDataAction (){
            let res = await ProductServices.getCountriesAPI();
            let data = res.data;
            return data;
        }, 
        
        async getStatesDataAction(_, payload) {
            let res = await ProductServices.getStatesApi(payload);
            let data = res.data;
            return data;
        },

        async getCitiesDataAction(_, payload) {
            let res = await ProductServices.getCitiesApi(payload);
            let data = res.data;
            return data;
        },

        async addOrderAction({ commit }, payload) {
            let res = await ProductServices.addOrderApi(payload);
            let data = res.data;
            if(data.success){
                commit('Auth/userBalanceUpdateMutation', `${data.balance}`, { root: true });
            }
            return data;
        },

        async addBalanceAction({ commit }, payload) {
            let res = await ProductServices.addBalanceApi(payload);
            let data = res.data;
            if(data.success){
                commit('Auth/userBalanceUpdateMutation', `${data.balance}`, { root: true });
            }
            return data;
        },

        async getOrdersDataAction(_, payload) {
            let res = await ProductServices.getOrdersApi(payload);
            let data = res.data;
            return data;
        },

        async getTabsDetailDataAction() {
            let res = await ProductServices.getTabsDetailApi();
            let data = res.data;
            return data;
        },

        async applyCouponAction(_, payload) {
            let res = await ProductServices.applyCouponApi(payload);
            let data = res.data;
            return data;
        },

        async getExistingCardsDataAction (){
            let res = await ProductServices.getExistingCardsAPI();
            let data = res.data;
            return data;
        }, 
        async getDraftOrderAction (_, payload){
            let res = await ProductServices.getDraftOrderAPI(payload);
            let data = res.data;
            return data;
        },  

        async deleteDraftOrderAction (_, payload){
            let res = await ProductServices.deleteDraftOrderAPI(payload);
            let data = res.data;
            return data;
        }, 

        async approvedDraftOrderAction (_, payload){
            let res = await ProductServices.approvedDraftOrderAPI(payload);
            let data = res.data;
            return data;
        }, 

        async domainDataAction (_, payload){
            let res = await ProductServices.domainDataAPI(payload);
            let data = res.data;
            return data;
        }, 

        async getOrderPlanCountAction (){
            let res = await ProductServices.orderPlanCountDataAPI();
            let data = res.data;
            return data;
        }, 
        
        async getSelectedCardDataAction(_, payload) {
            let res = await ProductServices.selectedCardDetailApi(payload);
            let data = res.data;
            return data;
        },

        async getReferralCouponAction() {
            let res = await ProductServices.getReferralCouponApi();
            let data = res.data;
            return data;
        },
        async stripePaymentMethodAction(_, payload) {
            let res = await ProductServices.stripePaymentMethodApi(payload);
            let data = res.data;
            return data;
        },

        async stripeSavePaymentMethodAction(_, payload) {
            let res = await ProductServices.stripeSavePaymentMethodApi(payload);
            let data = res.data;
            return data;
        }
    }
}