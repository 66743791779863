<template>
  <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
    <div class="text_heading_top_bar">
      <div class="text-heading-2">Payout History</div>
      <div class="btn-box select-domain-box" v-if="showPayoutBtn">
        <a
          href="javascript:;"
          class="btn-1"
          @click="requestPayoutOption()"
          >Request Payout
        </a>
      </div>
    </div>
    <div class="table-responsive">
      <div :class="{'scrollable': payoutHistories.length > 10}">
        <table class="addon-table">
          <tr>
            <th>Date</th>
            <th>Payout</th>
            <th>Pay via</th>
            <th>Transaction ID</th>
          </tr>
          <tr v-for="payoutHistory in payoutHistories" :key="payoutHistory.transaction_id">
            <td>{{ payoutHistory.paid_at }}</td>
            <td>{{ numberFormat(payoutHistory.payout, 'currency') }}</td>
            <td>{{ payoutHistory.type }}</td>
            <td>{{ payoutHistory.transaction_id }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { numberFormat } from '@/prod.js';

export default {
  props: ["earning_lists","historyPayouts","showPayoutBtn"],
  watch:{
    historyPayouts(){
      this.payoutHistories = this.historyPayouts;
    }
  },
  components: {},
  mounted() {},
  data() {
    return {
      numberFormat,
      loading: false,
      payoutHistories: [],
    };
  },
  methods: {
    requestPayoutOption() {
      this.$emit("requestPayoutOption");
    },
    redirectURL(url) {
      const pattern = /^(http:\/\/|https:\/\/)/i;
      const hasHttpOrHttps = pattern.test(url);

      let finalUrl = url;
      if (!hasHttpOrHttps) {
        finalUrl = "https://" + url;
      }

      const newTab = window.open(finalUrl, "_blank");
      if (newTab) {
        newTab.focus();
      }
    },
  },
  computed: {
    liveLinksLength() {
      return this.live_links ? true : false;
    },
  },
};
</script>
