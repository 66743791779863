import { UserProfileServices } from '@/services/userProfileServices';
export default {
    namespaced: true,

    actions:{

        async getAllUserProfileDataAction (){
            let res = await UserProfileServices.getAllUserProfileDataAPI();
            let data = res.data;
            return data;
        }, 
        async postAllUpdateUserDataAction({ commit }, payload){
            let res = await UserProfileServices.postAllUpdateUserDataAPI(payload);
            let data = res.data;
            if(data.success){
                commit('Auth/userProfileNameUpdateMutation', `${data.personal_detail.name}`, { root: true });
            }
            return data;
        }, 
        async postSaveImageDataAction({ commit }, payload){
            let res = await UserProfileServices.postSaveimageDataAPI(payload);
            let data = res.data;
            if(data.success){
                commit('Auth/userProfileImageUpdateMutation', `${data.image}`, { root: true });
            }
            return data;
        }, 
        async postAlternateEmailDataAction(_, payload){
            let res = await UserProfileServices.postAlternateEmailDataAPI(payload);
            let data = res.data;
            return data;
        }, 

        async changePasswordAction(_, payload){
            let res = await UserProfileServices.changePasswordAPI(payload);
            let data = res.data; 
            return data;
        },
        async removeAlternateEmailAction(_, payload){
            let res = await UserProfileServices.removeAlternateEmailAPI(payload);
            let data = res.data; 
            return data;
        },
        async addNewCardAction(_, payload){
            let res = await UserProfileServices.addNewCardAPI(payload);
            let data = res.data; 
            return data;
        },
        async removeCardAction(_, payload){
            let res = await UserProfileServices.removeCardAPI(payload);
            let data = res.data; 
            return data;
        },  
        async getProfileAddressDataAction (){
            let res = await UserProfileServices.getProfileAddressDataAPI();
            let data = res.data;
            return data;
        },
        async savePayoutDetailsDataAction(_, payload){
            let res = await UserProfileServices.savePayoutDetailsAPI(payload);
            let data = res.data; 
            return data;
        },
        async getPayoutDetailsDataAction (){
            let res = await UserProfileServices.getPayoutDetailsDataAPI();
            let data = res.data;
            return data;
        },
    }
}